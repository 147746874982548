import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./style.scss"; // Optional: for additional styling

const testimonials = [
  {
    quote:
      "'Chinmaya'- A legacy of excellence...An institution which provides quality education to my child. It has efficient and supportive staff that nurtures my child as their own.",
    author: "Mrs. Poonam Talreja",
  },
  {
    quote:
      "The school provides a well-rounded environment with excellent teaching methods that encourage academic success. Teachers are innovative and supportive, ensuring students stay engaged. A variety of extracurricular activities further enhance personal growth and skill development.",
    author: "Mr. Rakesh Kumar Gunjan",
  },
  {
    quote:
      "Chinmaya Vidyalaya is doing excellent in all the fields especially giving a lot of exposure to children. Very nicely planned and organized Academic programme.",
    author: "Mr. Pramod Kumar Sharma",
  },
  {
    quote:
      "I am impressed with Chinmaya Vidyalaya's curriculum and teaching methods which is different from other schools. Well planned worksheets for practice makes my child think beyond prescribed Textbooks.",
    author: "Ms. Aradhana Mohanty",
  },
];

const TestimonialSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,
  };

  return (
    <div className="testimonial-slider">
      <h2 className="heading">Hear From Parents</h2>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <p className="quote">“{testimonial.quote}”</p>
            <p className="author">- {testimonial.author}</p>
          </div>
        ))}
      </Slider>
      <br />
      <br />
    </div>
  );
};

export default TestimonialSlider;
