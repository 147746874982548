import React, { useState } from "react";
import "./styles.scss";
import axios from "axios";
import logo from "../../../images/logo.png";
import Faq from "react-faq-component";
import Footer from "../../Footer";
import Seo from "../../seo";
import TestimonialSlider from "../../TestimonialSlider";
import NewsTicker from "../../Newsticker";
import GoogleReviewWidget from "../../GoogleReviewWidget";
import StickySocial from "../../StickySocial";

const initialData = {
  fullName: "",
  class: "",
  email: "",
  phoneNumber: "",
  childAge: "",
  city: "",
  remarks: "",
  source: "",
  tenDigitMobile: "",
};

function AdmissionLandingPage() {
  const [formData, setFormData] = useState(initialData);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const data = {
    title: "",
    rows: [
      {
        title: "What are the admission criteria for Nursery?",
        content: (
          <p>
            The admission criteria and scale are available on the{" "}
            <a href="/nursery-admissions">Vidyalaya website</a>, with step-wise
            instructions on how to apply. Please check.
          </p>
        ),
      },
      {
        title: "What is the age criteria for nursery admission?",
        content: (
          <p>
            Children born between 01.04.2021 & 31.03.2022 will be eligible for
            admission to <a href="/nursery-admissions">Pre-School (Nursery)</a>{" "}
            class.
          </p>
        ),
      },
      {
        title: "What are the school’s admission dates and deadlines?",
        content: (
          <p>
            Online registration begins on 28th November, and the last date for
            registration is 20th December. You can view the full schedule on the{" "}
            <a href="/registrations">Vidyalaya website</a>; it has the dates for
            the first and second list too.
          </p>
        ),
      },
      {
        title: "How can I obtain the admission form?",
        content: (
          <p>
            Registration forms are available online on the{" "}
            <a href="/registrations/">Vidyalaya website</a> and physical forms
            are available at the school reception desk.
          </p>
        ),
      },
    ],
  };

  const styles = {
    // bgColor: 'white',
    titleTextColor: "black",
    rowTitleColor: "black",
    // rowContentColor: 'grey',
    rowContentPaddingLeft: "20px",
    // arrowColor: "red",
  };

  const config = {
    animate: true,
    // arrowIcon: "V",
    tabFocus: true,
  };

  const validateForm = () => {
    const newErrors = {};

    const data = {};
    Object.keys(formData).forEach((key) => {
      data[key] =
        typeof formData[key] == "string" ? formData[key].trim() : formData[key];
    });

    [
      "fullName",
      "class",
      "email",
      "phoneNumber",
      "tenDigitMobile",
      "childAge",
      "city",
    ].forEach((field) => {
      if (!data[field]) {
        newErrors[field] = "This field is required";
      }
    });

    // Validate email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (data.email && !emailRegex.test(data.email)) {
      newErrors.email = "Invalid email address";
    }

    // Validate phone number format
    const phoneRegex = /^[0-9]{10}$/;
    if (data.tenDigitMobile && !phoneRegex.test(data.tenDigitMobile)) {
      newErrors.tenDigitMobile =
        "Invalid format. Mobile number should be a 10 digit number";
    }

    setErrors(newErrors);

    // Return true if there are no errors
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErrors((prevData) => {
      let data = { ...prevData };
      delete data[name];
      return data;
    });
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);

    if (validateForm()) {
      console.log("Form data submitted:", formData);

      try {
        const response = await axios.post(
          "https://chinmayavvdelhi.ac.in/api/public/admissions.php/admission-ads-enquiry",
          formData
        );

        // console.log(response);
        setIsSubmitting(false);
        setFormData(initialData);

        setShowSuccess(true);

        window.location.href = "/best-cbse-school-in-delhi-admission/success";
        setTimeout(() => {
          setShowSuccess(false);
        }, 5000);
      } catch (e) {
        console.log(e);
        setIsSubmitting(false);

        if (e.response && e.response.data.error) {
          setErrors(e.response.data.details);
        }
        // console.log(e.response.data);
      }
    } else {
      setIsSubmitting(false);
      console.log("Form validation failed");
    }
  };

  return (
    <main className="admissionleads">
      <Seo
        title="Top CBSE School in Delhi | Chinmaya Vidyalaya Admission Open 2024-25"
        description="Enroll your child at Chinmaya Vidyalaya, Vasant Vihar - one of the best CBSE schools in Delhi. Offering holistic education, world-class facilities, and value-based learning. Admissions open for 2024-25! Explore now."
        img="https://chinmayavvdelhi.ac.in/assets/images/admissions-microsite/link-preview.jpg"
      />
      <nav>
        <a className="back" href="/">
          <i className="fa-solid fa-arrow-left"></i> Back to Home
        </a>
        <a href="/" className="admissionleads__logo">
          <img
            className="admissionleads__logo__img"
            alt="Chinmaya Logo"
            src={logo}
          ></img>
          <div className="admissionleads__logo__text">
            Chinmaya Vidyalaya
            <br />
            Vasant Vihar, New Delhi
          </div>
        </a>
      </nav>
      <StickySocial />
      <NewsTicker
        data={[
          { text: "Admission Criteria", link: "/nursery-admissions" },
          {
            text: "Admission Process",
            link: "/online-registration-process/",
          },
          { text: "Admission Schedule & Dates", link: "/nursery-admissions" },
          { text: "Registration Form", link: "/registrations/" },
        ]}
      />

      <div className="admissionleads__banner">
        <img
          className="admissionleads__banner__image"
          src="https://chinmayavvdelhi.ac.in/assets/images/admissions-microsite/landing-page-hero-2024.jpg"
        ></img>
        <img
          className="admissionleads__banner__imagem"
          src="https://chinmayavvdelhi.ac.in/assets/images/admissions-microsite/landing-page-hero-2024-m.jpg"
        ></img>
        <div className="admissionleads__banner__text">
          {/* <h1>
            <span> Admissions Open </span> <br />
            Academic Session 2024-2025
          </h1> */}
        </div>
      </div>

      <div className="admissionleads__awards">
        <h2 className="heading">School Awards</h2>
        <ul>
          <li>
            <i className="fas fa-star"></i>
            <div>
              <div>
                Ranked No.1 for Most Effective Blended Learning Model in Delhi
                by EducationToday
              </div>
            </div>
          </li>
          <li>
            <i className="fas fa-star"></i>
            <div>
              Ranked No.3 in South-West Delhi Leaders Category by Times School
              Survey 2024
            </div>
          </li>
          <li>
            <i className="fas fa-star"></i>
            <div>Amongst 17 top schools rated AAAA+ by Careers360</div>
          </li>
          <li>
            <i className="fas fa-star"></i>
            <div>
              Recipient of The Best School Award at FAP National Awards 2024
            </div>
          </li>
          <li>
            <i className="fas fa-star"></i>
            <div>
              National level recognition for being one of ‘Top 50 Futuristic
              Schools of India’ by Edu-Mirror in 2022
            </div>
          </li>
          <li>
            <i className="fas fa-star"></i>
            <div>
              Recipient of Spiritual and Value Education (SAVE) Award in 2022
            </div>
          </li>
        </ul>
        <br />
        <br />
        <video
          src="https://chinmayavvdelhi.ac.in/assets/admission-video-2024.mp4"
          controls
        />
      </div>
      <div className="admissionleads__formcontainer">
        <form className="admissionleads__form" onSubmit={handleSubmit}>
          <h4>
            Fill in the form below to submit your enquiry, and a team will be in
            touch shortly.
          </h4>
          <div className="half">
            <label htmlFor="fullName">Full Name* </label>
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              className={`${errors.fullName && "input-error"}`}
              placeholder="Enter your full name"
            />
            {errors.fullName && (
              <span className="error">{errors.fullName}</span>
            )}
          </div>
          <div className="half">
            <label>Admission Class* </label>
            <input
              type="text"
              name="class"
              max={50}
              value={formData.class}
              placeholder="Enter admission class"
              className={`${errors.class && "input-error"}`}
              onChange={handleChange}
            />
            {errors.class && <span className="error">{errors.class}</span>}
          </div>
          <div>
            <label>Email* </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={`${errors.email && "input-error"}`}
              placeholder="Enter your email"
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
          <div className="half">
            <label>Phone Number*</label>
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              className={`${errors.phoneNumber && "input-error"}`}
              placeholder="Enter your phone number"
            />
            {errors.phoneNumber && (
              <span className="error">{errors.phoneNumber}</span>
            )}
          </div>{" "}
          <div className="half">
            <label>10-Digit Mobile* </label>
            <input
              type="tel"
              name="tenDigitMobile"
              value={formData.tenDigitMobile}
              onChange={handleChange}
              className={`${errors.tenDigitMobile && "input-error"}`}
              placeholder="Enter your 10-digit mobile number"
            />
            {errors.tenDigitMobile && (
              <span className="error">{errors.tenDigitMobile}</span>
            )}
          </div>
          <div className="half">
            <label>Child Age* </label>
            <input
              type="text"
              name="childAge"
              value={formData.childAge}
              onChange={handleChange}
              className={`${errors.childAge && "input-error"}`}
              placeholder="Enter child's age"
            />
            {errors.childAge && (
              <span className="error">{errors.childAge}</span>
            )}
          </div>
          <div className="half">
            <label>City* </label>
            <input
              type="text"
              name="city"
              value={formData.city}
              onChange={handleChange}
              className={`${errors.city && "input-error"}`}
              placeholder="Enter your city"
            />
            {errors.city && <span className="error">{errors.city}</span>}
          </div>
          <div>
            <label>How did you find us?</label>
            <input
              type="text"
              name="source"
              value={formData.source}
              className={`${errors.source && "input-error"}`}
              onChange={handleChange}
              placeholder="Enter the source (Google, Facebook, etc.)"
            />
            {errors.source && <span className="error">{errors.source}</span>}
          </div>
          <div>
            <label>Remarks </label>
            <textarea
              name="remarks"
              value={formData.remarks}
              onChange={handleChange}
              className={`${errors.remarks && "input-error"}`}
              placeholder="Add any remarks"
            />

            {errors.remarks && <span className="error">{errors.remarks}</span>}
          </div>
          <button disabled={isSubmitting} type="submit">
            {!isSubmitting ? "Submit" : "...Submitting"}
          </button>
          {showSuccess && <div className="success">Submitted successfully</div>}
        </form>
      </div>
      <TestimonialSlider />

      <div className="admissionleads__points">
        <div className="admissionleads__points__row">
          <div className="admissionleads__points__col">
            <h2>Salient Features</h2>
            <ul>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>Chinmaya Vision Programme for holistic development</div>
              </li>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>Over 40 years of educational excellence</div>
              </li>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>Enduring legacy of distinction as Flagship School</div>
              </li>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>Strong commitment to diversity, equity and inclusion</div>
              </li>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>
                  Exposure to culture and timeless Indian values with signature
                  activities
                </div>
              </li>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>
                  Under the aegis of Central Chinmaya Mission Education Cell
                </div>
              </li>
            </ul>
          </div>
          <div className="admissionleads__points__col">
            <h2>Scholastic</h2>
            <ul>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>Excellent CBSE results year on year</div>
              </li>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>Experiential learning for skill-development</div>
              </li>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>Innovative pedagogical methodologies</div>
              </li>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>Focus on foundational learning</div>
              </li>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>Stellar faculty of educationists</div>
              </li>
            </ul>
          </div>

          <div className="admissionleads__points__col">
            <h2>Co-Scholastic</h2>
            <ul>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>Strong focus on performing arts & visual arts</div>
              </li>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>
                  Multitude of co-curricular clubs such as Robotics, Astronomy,
                  Sewa, MUN & INTERACT
                </div>
              </li>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>
                  Vyaktitva- value-based education for holistic development
                </div>
              </li>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>Highly qualified coaches & skilled instructors</div>
              </li>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>Excellent outcomes in inter-school competitions</div>
              </li>
            </ul>
          </div>
          <div className="admissionleads__points__col">
            <h2>Facilities</h2>
            <ul>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>
                  State-of-the-art infrastructure with world-class athletics
                  field
                </div>
              </li>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>AC Bus services for Delhi-NCR</div>
              </li>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>Joyful, secure, student-friendly spaces</div>
              </li>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>
                  Lush green campus of 3.75 acres in the heart of South Delhi
                </div>
              </li>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>
                  Fully-equipped Science Labs, Computer Labs & Atal Tinkering
                  Lab
                </div>
              </li>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>Audio-visual facilities for SMART learning</div>
              </li>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>Well-resourced Library</div>
              </li>
              <li>
                <i className="fa-solid fa-star"></i>
                <div>In-house medical care</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="admissionleads__faq">
        <div className="admissionleads__faq__container">
          <h3 className="heading">Frequently Asked Questions (FAQs)</h3>
          <Faq data={data} styles={styles} config={config} />
        </div>
      </div>
      <div className="admissionleads__collabimages">
        <img
          className="admissionleads__collabimages__img"
          alt=""
          src="https://chinmayavvdelhi.ac.in/images/Blackele/cce1_1.png"
        ></img>

        <img
          className="admissionleads__collabimages__img"
          alt=""
          src="https://chinmayavvdelhi.ac.in/assets/images/school-awards-logo.jpg"
        ></img>

        <img
          className="admissionleads__collabimages__img"
          alt=""
          src="https://chinmayavvdelhi.ac.in/assets/images/Times-school-survey-icon.png"
        ></img>
        <img
          className="admissionleads__collabimages__img"
          alt=""
          src="https://chinmayavvdelhi.ac.in/assets/images/admissions-microsite/Big-FM-logo.png"
        ></img>
        <GoogleReviewWidget />
      </div>
      <Footer />
    </main>
  );
}

export default AdmissionLandingPage;
